<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation','status']" :is_page="false" ref="list" rowKey="id">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加岗位</a-button>
			</template>
			<template slot="status" slot-scope="data">
				<a-tag color="#108ee9">
					<span v-for="item in status_list" :key="item.key"
						v-show="item.key == data.text">{{item.value}}</span>
				</a-tag>
			</template>

			<template slot="operation" slot-scope="data">
				<a @click="edit(data.record, 'add')">添加下级岗位</a>
				<a-divider type="vertical" />
				<a v-auth="'change'" @click="edit(data.record)">编辑</a>
				<a-divider type="vertical" />
				<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
			</template>
		</TableList>
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
			<template slot="ranktype" slot-scope="data">
				<a-radio-group name="radioGroup" v-model="addformState.type">
					<a-radio value="alone">
						单个职级
					</a-radio>
					<a-radio value="range">
						职级范围
					</a-radio>
				</a-radio-group>
			</template>
			<template slot="typename" slot-scope="data">
				<a-cascader v-if="addformState.type=='alone'" placeholder="请选择"
					:fieldNames="{children:'children', label:'name', value: 'id' }" v-model:value="addformState.rank"
					:options="rankTreeList" />
				<div v-else style="display: flex;align-items: center;">
					<a-cascader placeholder="请选择" :fieldNames="{children:'children', label:'name', value: 'id' }"
						v-model:value="addformState.rank" :options="rankTreeList" />~<a-cascader placeholder="请选择"
						:fieldNames="{children:'children', label:'name', value: 'id' }" v-model:value="rank1"
						:options="rankTreeList" />
				</div>
			</template>

		</EditPop>
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import TableList from "@/components/TableList";
	import { message } from 'ant-design-vue';
	import {
		postList,
		changePost,
		deletePost,
		getPostList
	} from '@/api/user'
	import {
		rankTree,getRank
	} from '@/api/organization'
	// 
	const columns = [{
			title: "岗位名称",
			dataIndex: "name",
		},
		{
			title: "岗位编码",
			dataIndex: "code",
		},
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "人员编制",
			dataIndex: "headcount",
		},

		{
			title: "所属部门",
			dataIndex: "department_name",
		},
		
		{
			title: "职级",
			dataIndex: "rank_code",
		},
		{
			title: "职等",
			dataIndex: "rank_level",
		},
		{
			title: "备注",
			dataIndex: "remark",
		},
		{
			title: "操作",
			width: "150px",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	let status_list = [{
			key: 1,
			value: "启用"
		},
		{
			key: 0,
			value: "禁用"
		},
		{
			key: -1,
			value: "无效"
		}
	];



	export default {
		name: "Index",
		components: {
			EditPop,
			TableList
		},
		data() {
			return {
				rank1: [],
				rankTreeList: [],
				addformState: {
					type: 'alone',
					rank:[]
				},
				get_table_list: getPostList,
				columns,
				visible: false,
				status_list,
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [{
						type: "text",
						name: "name",
						title: "岗位职务",
						options: {}
					}],
				},
				form_data: {
					title: "添加",
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {},
						},
						{
							type: 'tree-select',
							name: 'pid',
							options: {},
							title: '上级岗位',
							treeData: [],
						},
						{
							type: "text",
							name: "name",
							title: "岗位名字",
							options: {}
						},
						{
							type: "text",
							name: "code",
							title: "岗位编码",
							options: {}
						},
						{
							type: 'tree-select',
							name: 'department_id',
							options: {},
							title: '所属部门',
							treeData: [],
						},
						{
							type: "text",
							name: "headcount",
							title: "人员编制",
							options: {}
						},
						{
							type: "slot",
							name: "ranktype",
							title: "职级类型",
							options: {
								rules: [{
									required: true,
									message: "请选择状态"
								}],
								initialValue: 'alone'
							},
							list: []
						},
						{
							type: "slot",
							name: "typename",
							title: "职级",
							options: {}
						},
						{
							type: "textarea",
							name: "remark",
							title: "备注",
							options: {}
						}
					]
				},
			};
		},
		async created() {
			this.get_rank()
			this.$method.get_department().then(res => {
				this.form_data.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res;

					}
				});
			});
		},

		methods: {
			get_rank() {
				rankTree().then(res => {
					this.rankTreeList = res.data.list
				})
			},
			add() {
				this.get_select(true)
				this.form_data.title = "添加"
				this.form_data.list.forEach(item => {
					item.options.initialValue = '';
				})

				this.visible = true;
			},
			edit(data, type = 'edit') {
				this.get_select()
				if (type == 'add') {
					this.form_data.title = "添加"
					this.form_data.list.forEach(item => {
						if (item.name == 'pid') {
							item.options.initialValue = data['id'];
						} else {
							item.options.initialValue = '';
						}
					})
				} else {
					this.form_data.title = "编辑",
					 this.get_Rank(data)
					this.form_data.list.forEach(item => {
					    item.options.initialValue = data[item.name];
					})
				}
				
				this.visible = true;
			},
			get_Rank(data){
				getRank({data:{
					role_id:data.id
				}}).then(res=>{
					this.addformState.type = res.data.type
						this.addformState.rank = res.data.rank_path[0]
						this.rank1 = res.data.rank_path[1]
					this.form_data.list.forEach(item => {
						item.options.initialValue = data[item.name];
						if (item.name == 'pid' && data[item.name] == 0) {
							item.options.initialValue = ''
						}
						
					})
				})
			},
			del(data) {
				deletePost({
					data: {
						id: data.id
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			submit(e) {
				if (!e.values.id) {
					e.values.id = '';
				}
				let rank = ''
				if (this.addformState.type == 'range') {
					if (this.rank1.length>0 && this.addformState.rank.length>0) {
						if(this.rank1[0] != this.addformState.rank[0]){
							message.warning('必须为同一通道的职级');
							return false
						}
						rank = this.addformState.rank[this.addformState.rank.length - 1] + ',' + this.rank1[this.rank1
						.length -1]
					}else{
						 message.warning('请选择职级范围');
						 	return false
					}
				} else {
					if (this.addformState.rank.length>0) {
						rank = this.addformState.rank[this.addformState.rank.length - 1]
					}else{
						message.warning('请选择职级');
							return false
					}
				}
				e.values.type = this.addformState.type
				e.values.rank = rank
				changePost({
					data: e.values,
					info: true,
				}).then(res => {
					this.visible = false;
					this.$refs.list.get_list();
					this.addformState.rank=[]
					this.addformState.type='alone'
				})
			},
			get_select() {
				this.$method.get_role_tree().then(res => {
					this.form_data.list = this.$method.set_form_list(this.form_data.list, 'pid', 'treeData', res)
				});
			}
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
</style>